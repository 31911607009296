<template>
  <AuthWrapper>
    <div class="auth-contents register">
      <a-form
        name="register"
        ref="formRef"
        :rules="rules"
        style="margin-top: 0px"
        :model="formState"
        @finish="handleSubmit"
        layout="vertical"
      >
        <div class="auth-form-action auth-title-language">
          <sdHeading as="h3" class="auth-title">
            {{ i18n.t('authPage.signUp') }}
          </sdHeading>
          <a-select
            class="sign-up__language"
            v-model:value="currentLanguage"
            @change="changeLanguage"
            :options="languages"
            ref="select"
          >
          </a-select>
        </div>
        <a-form-item ref="email" name="email" :label="i18n.t('authPage.email')">
          <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
        </a-form-item>
        <a-form-item ref="password" name="password" :label="i18n.t('authPage.password')">
          <a-input-password v-model:value="formState.password" />
        </a-form-item>
        <a-form-item
          ref="password_confirmation"
          name="password_confirmation"
          :label="i18n.t('authPage.password_confirmation')"
        >
          <a-input-password v-model:value="formState.password_confirmation" />
        </a-form-item>

        <!--<a-form-item ref="referral_code" name="referral_code" :label="i18n.t('authPage.referral_code')">
          <a-input style="witdh:100%;" type="text" v-model:value="formState.referral_code" />
        </a-form-item>-->
        <div class="auth-form-action auth-pc">
          <router-link class="forgot-pass-link" :to="{ name: 'forgotPassword' }">
            {{ i18n.t('authPage.forgotPassword') }}
          </router-link>
          <router-link class="forgot-pass-link" :to="{ name: 'resendCode' }">
            {{ i18n.t('authPage.resendConfirmCodeAgain') }}
          </router-link>
        </div>
        <div class="auth-form-action">
          <p v-html="i18n.t('authPage.signUpCheckBox')"></p>
        </div>
        <a-form-item>
          <div class="auth-form-action">
            <sdButton class="btn-create" htmlType="submit" :disabled="isLoading" type="primary" size="large">
              {{ i18n.t('authPage.createAcc') }}
            </sdButton>
            <p class="sign-up__to-login">
              <router-link :to="{ name: 'login' }">{{ i18n.t('authPage.signIn') }}</router-link>
            </p>
          </div>
        </a-form-item>
        <div class="auth-form-action auth-mobile">
          <router-link class="forgot-pass-link" :to="{ name: 'forgotPassword' }">
            {{ i18n.t('authPage.forgotPassword') }}
          </router-link>
          <router-link class="forgot-pass-link" :to="{ name: 'resendCode' }">
            {{ i18n.t('authPage.resendConfirmCodeAgain') }}
          </router-link>
        </div>
      </a-form>
    </div>
  </AuthWrapper>
</template>

<script>
import { AuthWrapper } from './style';
import { reactive, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import helper from '@/config/helpers/helper';
import { message } from 'ant-design-vue';
import { languages } from '@/config/helpers/JSONdata';
import { useRoute, useRouter } from 'vue-router';
import { getCookie, setCookie } from '@/utility/localStorageControl';

const SignUp = {
  name: 'SignUp',
  components: { AuthWrapper },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { dispatch, state } = useStore();
    const i18n = useI18n();
    const isLoading = computed(() => state.auth.loading);
    const currentLanguage = ref(getCookie('language'));
    const formatComp = computed(() => (i18n.locale.value == 'ru' ? 'DD.MM.YYYY' : 'MM-DD-YYYY'));

    const formState = reactive({
      referral_code: route.query.ref || getCookie('referral'),
      email: '',
      password: '',
      password_confirmation: '',
    });
    const formRef = ref();

    const emailValidator = async (rule, value) => {
      if (!rules.email[0].trigger[1]) {
        rules.email[0].trigger[1] = 'change';
      }
      if (formState.email == '') {
        return Promise.reject(i18n.t('authPage.errorEmail'));
      }

      const errorMsg = helper().checkUserMail({ email: value });

      if (errorMsg !== true) {
        return Promise.reject(i18n.t(errorMsg));
      } else {
        return Promise.resolve();
      }
    };

    const passwordValidator = ({ field }) => {
      if (!rules[field][0].trigger[1]) {
        rules[field][0].trigger[1] = 'change';
      }
      if (formState[field] == '') {
        return Promise.reject(i18n.t('authPage.errorPassword'));
      } else if (formState[field].length < 6) {
        return Promise.reject(i18n.t('authPage.errorPasswordMin'));
      }
      return Promise.resolve();
    };

    const rules = reactive({
      email: [
        {
          validator: emailValidator,
          trigger: ['blur'],
        },
      ],

      password: [
        {
          validator: passwordValidator,
          trigger: ['blur'],
        },
      ],

      password_confirmation: [
        {
          validator: passwordValidator,
          trigger: ['blur'],
        },
      ],
    });

    const handleSubmit = () => {
      formState.locale = getCookie('language');
      let i = 0;
      const errorMsg = [helper().checkUserMail(formState)];

      errorMsg.forEach((e) => {
        if (e !== true) {
          i++;
          message.warning(i18n.t(e));
        }
      });

      if (i == 0) {
        dispatch('auth/register', formState).then((res) => {
          if (!res.errors) {
            router.push({ name: 'register-success', query: { ...router.query } }, undefined, { scroll: false });
          } else {
            if (typeof res.errors !== 'string') {
              for (const [key, value] of Object.entries(res.errors)) {
                if (key !== 'user_personal_datum') {
                  message.warning(
                    `${key != 'password_confirmation' && key != 'password' ? i18n.t('authPage.' + key) : ''}  ${
                      value[0]
                    }`,
                  );
                }
              }
            } else {
              message.warning(res.errors);
            }
          }
        });
      }
    };

    const changeLanguage = (data) => {
      currentLanguage.value = data;
      i18n.locale.value = data;
      setCookie({ name: 'language', value: data });
      formRef.value.clearValidate();
    };

    return {
      formState,
      i18n,
      languages,
      currentLanguage,
      rules,
      isLoading,
      formRef,
      formatComp,
      handleSubmit,
      changeLanguage,
    };
  },
};

export default SignUp;
</script>
