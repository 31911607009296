/* eslint-disable */

import { i18n } from '../i18n';

export default ()=>{
	const { t } = i18n.global;

    const checkUserMail = (data)=> {
      const { email } = data;
      if (checkEmailSymbols(email)) {
        return 'messages.strHasSpecialSymbols';
      } else if (validateEmail(email)) {
        return true;
      } else {
        return 'messages.emailDontHasSymbol';
      }
    };

    const checkUserData = (data)=> {
      let i = 0;
      for (const value of Object.entries(data)) {
        if (
          (value[1] == data.first_name && checkSymbols(value[1])) ||
          (value[1] == data.middle_name && checkSymbols(value[1])) ||
          (value[1] == data.last_name && checkSymbols(value[1]))
        ) {
          i++;
        }
      }
      if (i == 0) {
        return true;
      } else {
        return 'messages.strHasSpecialSymbols';
      }
    };

		const errorValidator = (error, isNames)=> {
      let errorMessage = [];
      if (typeof error === "object") {
        Object.entries(error).map(value => {
          if (value[0] != "password" && value[0] != "errors") {
            errorMessage.push(
              (!isNames ? t(`authPage.${value[0]}`) + " " : '' ) +
                (typeof value[1] == "object" ? value[1][0] : value[1])
            );
          } else {
            errorMessage.push(
              typeof value[1] == "object" ? value[1][0] : value[1]
            );
          }
        });
      } else {
        errorMessage.push(error);
      }
			
			return errorMessage;
    };

    const validateEmail = (email)=> {
      const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return re.test(email);
    };

    const checkSymbols = (str)=> {
      var regex = /[!#$%^&*()+\=\[\]{};'"\\|,<>\/?1-9`]/g;
      return regex.test(str);
    };

    const checkEmailSymbols = (str)=> {
      var regex = /[!#&*()+\=\[\]{};'"\\|,<>\/?`]/g;
      return regex.test(str);
    };

    const checkEmail = (str)=> {
      var regex = /[@.]/g;
      return regex.test(str);
    };


		return {
			checkUserMail,
			checkUserData,
			checkSymbols,
			checkEmailSymbols,
			checkEmail,
			checkEmailSymbols,
			errorValidator,
			validateEmail
		}
};
