<template>
  <AuthWrapper>
    <div class="auth-contents">
      <div class="auth-container">
        <sdHeading as="h3" class="sign-in__header">
          {{ i18n.t('messages.successRegSubTitle') }}
          <a-select
            class="sign-in__language"
            v-model:value="currentLanguage"
            @change="changeLanguage"
            :options="languages"
            ref="select"
          >
          </a-select>
        </sdHeading>
        <p>{{ i18n.t('messages.successRegConfirmationInfo') }}</p>
        <p>{{ i18n.t('messages.successRegJunkConfirmationInfo') }}</p>
        <p>{{ i18n.t('messages.successRegDontTakeCodeInfo') }}</p>
        <div class="auth-form-action">
          <div class="auth-form-action auth-mobile">
            <p class="sign-up__to-login">
              <router-link to="/auth/login">{{ i18n.t('authPage.signIn') }}</router-link>
            </p>
            <p class="sign-up__to-login">
              <router-link class="forgot-pass-link" to="/auth/resend-code">
                {{ i18n.t('authPage.resendConfirmCodeAgain') }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </AuthWrapper>
</template>

<script>
import { AuthWrapper } from './style';
import { useI18n } from 'vue-i18n';
import { languages } from '@/config/helpers/JSONdata.js';
import { onMounted, ref } from '@vue/runtime-core';
import { getCookie, setCookie } from '@/utility/localStorageControl';

const SignUp = {
  name: 'SignUp',
  components: { AuthWrapper },
  setup() {
    const i18n = useI18n();

    const currentLanguage = ref(getCookie('language'));

    const changeLanguage = (data) => {
      currentLanguage.value = data;
      i18n.locale.value = data;
      setCookie({ name: 'language', value: data });
    };

    onMounted(() => {
      document.scrollTop;
    });
    return {
      i18n,
      languages,
      currentLanguage,
      changeLanguage,
    };
  },
};

export default SignUp;
</script>
<style lang="scss" scoped>
.auth-container {
  margin-top: 60px;
  p {
    margin: 20px 0;
  }
  h1 {
    margin-bottom: 40px;
  }
  .auth-form-action {
    p {
      font-size: 20px;
      margin: 0;
    }
    div {
      margin: 40px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
